<template>
  <div class="w-full">
    <v-container
      v-if="!isCheckout"
      :class="isLoggedIn ? 'py-8' : 'pt-8 py-4'"
    >
      <v-col cols="12">
        <h2 v-if="!isPreRegister" class="title-seven-club-member">
          <img
            src="@/assets/seven-club-member/logo.png"
            height="36"
            class="mr-2"
            alt="7 Club Member"
          />
          <span class="font-bold text-black">
            Club Member
          </span>
        </h2>

        <div v-if="!isPreRegister" class="desc-seven-club-member">
          หากเป็นสมาชิก
          <span class="font-bold">7 Club Member</span>
          สามารถระบุเบอร์โทรศัพท์
          <br />
          เพื่อนำข้อมูลสมาชิกมาใช้งานได้เลย!
        </div>

        <v-row v-bind="{ justify: 'center' }">
          <v-col cols="12" sm="7" class="text-center pb-0">
            <div>
              <v-row v-bind="{ justify: 'center', align: 'center' }">
                <v-col cols="7" md="6">
                  <v-text-field
                    v-model="phoneNumber"
                    :error="$v.phoneNumber.$error"
                    :error-messages="
                      $v.phoneNumber.$error && phoneNumberErrorMessage
                    "
                    :disabled="isMember"
                    :color="colorBnn['color-bnn']"
                    background-color="white"
                    class="text-sm"
                    type="text"
                    maxlength="10"
                    minlength="10"
                    oninput="this.value=this.value.slice(0,this.maxLength).replace(/[^\d]+/g, '')"
                    inputmode="tel"
                    data-pptr="phoneNumber"
                    label="เบอร์โทรศัพท์"
                    large
                    :outlined="!isBnn"
                    @focus="$v.$reset()"
                    @keyup.enter="handleOtpMemberRequest"
                  >
                    <template v-if="isMember" v-slot:append>
                      <v-icon color="green" size="20">
                        mdi-check
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="5" md="3">
                  <v-btn
                    block
                    depressed
                    width="180px"
                    :class="[
                      'font-bold',
                      { 'text-white': !isMember },
                    ]"
                    :color="colorBnn['color-bnn']"
                    @click="
                      () => {
                        isMember
                          ? openMemberLogout()
                          : handleOtpMemberRequest()
                      }
                    "
                  >
                    <template v-if="isMember">
                      เปลี่ยนข้อมูล
                    </template>
                    <template v-else>
                      ตรวจสอบบัญชี
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <template v-if="isCanScanQrCode">
            <div class="w-full"></div>

            <v-col cols="12" sm="7" class="text-center">
              <div class="divider-with-text">หรือ</div>
            </v-col>

            <div class="w-full"></div>

            <v-col cols="auto">
              <v-btn
                depressed
                rounded
                large
                :color="colorBnn['color-bnn-light']"
                class="btn-scan-qr-code"
                @click="handleScanQrCode"
              >
                <v-icon size="16" class="mr-2">
                  mdi-qrcode-scan
                </v-icon>
                Scan QR Code
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              class="text-center text-sm pd-0"
              style="color: #808080"
            >
              สำหรับพนักงานสแกน QR Code ของสมาชิก
            </v-col>

            <QrCodeReader
              ref="QrCodeReader"
              @result="handleResultQrCodeReader"
            />
          </template>
        </v-row>
      </v-col>
    </v-container>

    <div v-else class="seven-club-member-studio7">
      <div v-if="!isPreRegister" class="header">
        <h2 class="title-seven-club-member headline-m">
          <img
            src="@/assets/seven-club-member/logo.png"
            height="36"
            class="mr-2"
            alt="7 Club Member"
          />
          Club Member
        </h2>

        <div class="desc-seven-club-member text-left">
          หากเป็นสมาชิก
          <span class="font-bold">7 Club Member</span>
          สามารถระบุเบอร์โทรศัพท์ เพื่อนำข้อมูลสมาชิกมาใช้งานได้เลย!
        </div>
      </div>

      <div
        :class="[
          'member-container',
          { 'is-pre-register': isPreRegister },
        ]"
      >
        <div>
          <v-text-field
            v-model="phoneNumber"
            :error="$v.phoneNumber.$error"
            :error-messages="
              $v.phoneNumber.$error && phoneNumberErrorMessage
            "
            :hide-details="!$v.phoneNumber.$error"
            :disabled="isMember"
            :color="colorBnn['color-bnn']"
            background-color="white"
            class="text-sm"
            type="text"
            maxlength="10"
            minlength="10"
            oninput="this.value=this.value.slice(0,this.maxLength).replace(/[^\d]+/g, '')"
            inputmode="tel"
            data-pptr="phoneNumber"
            label="เบอร์โทรศัพท์"
            large
            :outlined="!isBnn"
            @focus="$v.$reset()"
            @keyup.enter="handleOtpMemberRequest"
          >
            <template v-if="isMember" v-slot:append>
              <v-icon color="green" size="20">
                mdi-check
              </v-icon>
            </template>
          </v-text-field>
        </div>
        <div>
          <v-btn
            depressed
            large
            block
            :class="['font-bold', { 'text-white': !isMember }]"
            :color="
              !isMember
                ? colorBnn['color-bnn']
                : colorBnn['color-bnn-light']
            "
            width="180px"
            @click="
              () => {
                isMember
                  ? openMemberLogout()
                  : handleOtpMemberRequest()
              }
            "
          >
            <template v-if="isMember">
              เปลี่ยนข้อมูล
            </template>
            <template v-else>
              ตรวจสอบบัญชี
            </template>
          </v-btn>
        </div>
      </div>

      <template v-if="isCanScanQrCode">
        <div class="text-center">
          <div class="divider-with-text">หรือ</div>
        </div>

        <div>
          <v-btn
            depressed
            rounded
            large
            :color="colorBnn['color-bnn-light']"
            class="btn-scan-qr-code"
            @click="handleScanQrCode"
          >
            <v-icon size="16" class="mr-2">
              mdi-qrcode-scan
            </v-icon>
            Scan QR Code
          </v-btn>
        </div>

        <div class=" text-sm pd-0" style="color: #808080">
          สำหรับพนักงานสแกน QR Code ของสมาชิก
        </div>

        <QrCodeReader
          ref="QrCodeReader"
          @result="handleResultQrCodeReader"
        />
      </template>

      <div v-if="!isPreRegister" class="text-center">
        <div class="divider-with-text">หรือ</div>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="340">
      <v-card>
        <div class="header-modal-container">
          <div class="header-modal">
            <span class="header-modal-title">
              ยืนยัน OTP ที่ท่านได้รับ
            </span>
          </div>
        </div>

        <div class="body-modal">
          <v-row v-bind="{ justify: 'end', 'no-gutters': true }">
            <v-col cols="12">
              <div class="input-container">
                <div
                  ref="otpInputs"
                  class="input-otp-container"
                  :class="{ 'is-invalid': isInvalidOtp }"
                >
                  <input
                    v-for="(none, index) in otp"
                    :key="index"
                    v-model="otp[index]"
                    :autofocus="index === 0"
                    :controls="false"
                    type="number"
                    inputmode="numeric"
                    autocomplete="one-time-code"
                    class="input-otp"
                    @input="handleInput($event, index), $v.$reset()"
                    @keydown="
                      handleKeydown($event, index), $v.$reset()
                    "
                    @keyup.enter="handleVerifyOtpMember"
                  />
                </div>
              </div>

              <div
                v-if="errorMessage || isInvalidOtp"
                class="error-message"
              >
                {{ errorMessage || 'กรุณาระบุรหัส OTP ให้ถูกต้อง' }}
              </div>

              <div class="text-sm text-center">
                หากท่านไม่ได้รับ SMS
                <span v-if="countDown">
                  ภายใน {{ countDown }} วินาที
                </span>
                <div
                  class="link"
                  :class="{ disabled: countDown > 0 }"
                  @click="
                    () => {
                      countDown > 0 ? null : handleOtpMemberRequest()
                    }
                  "
                >
                  คลิกที่นี่
                </div>
              </div>
            </v-col>

            <v-col cols="12" class="mt-8">
              <v-btn
                block
                depressed
                large
                class="font-bold"
                :dark="!isBnn"
                :color="colorBnn['color-bnn']"
                :loading="isLoading"
                @click="handleVerifyOtpMember"
              >
                ยืนยัน
              </v-btn>
            </v-col>

            <v-col cols="12" class="mt-2">
              <v-btn
                block
                depressed
                link
                large
                :disabled="isLoading"
                @click="close"
              >
                ยกเลิก
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLogout" persistent max-width="340">
      <v-card>
        <div class="header-modal-container">
          <div class="header-modal">
            <span class="header-modal-title">
              <div class="d-flex">
                <span class="font-bold text-black">
                  เปลี่ยนข้อมูล
                </span>
              </div>
            </span>
          </div>
        </div>

        <div class="body-modal">
          <v-row v-bind="{ justify: 'end', 'no-gutters': true }">
            <v-col class="text-center">
              ระบบจะล้างข้อมูลที่คุณกรอกไว้ทั้งหมด
              คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนข้อมูล ?
            </v-col>

            <v-col cols="12" class="mt-8">
              <v-btn
                block
                depressed
                large
                color="#FF4252"
                class="text-white font-bold"
                @click="handleMemberLogout"
              >
                เปลี่ยนข้อมูล
              </v-btn>
            </v-col>

            <v-col cols="12" class="mt-4">
              <v-btn
                block
                depressed
                link
                large
                @click="closeMemberLogout"
              >
                ยกเลิก
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import colorBnn from '@/config/color'
import isBnn from '@/config/isBnn'
import QrCodeReader from '@/components/bnn/QrCodeReader'
import moment from 'moment'
import { STOCK_CARD_TYPE } from '@/config/stockCard'

export default {
  props: {
    title: {
      type: String,
      default: 'ยืนยันตัวตน',
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    isPreRegister: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [validationMixin],

  components: {
    QrCodeReader,
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      member: state => state.user.member.data,
      uuid: state => state.user.member.uuid || null,
      isLoading: state => state.user.member.isLoading,
      isSuccess: state => state.user.member.isSuccess,
      isVerifySuccess: state => state.user.member.isVerifySuccess,
      isError: state => state.user.member.isError,
      errorMessage: state => state.user.member.errorMessage,
    }),

    isMember() {
      return localStorage.getItem('isMember') || this.member
    },

    isInvalidOtp() {
      return this.isError || this.$v.otp.$error
    },

    isCanScanQrCode() {
      return (
        this.isLoggedIn &&
        this.$route.query.t === STOCK_CARD_TYPE.STORE
      )
    },
  },

  data() {
    return {
      // import
      colorBnn,
      isBnn,

      dialog: false,
      dialogLogout: false,
      countDown: 0,
      countDownInterval: null,
      phoneNumber: localStorage.getItem('isMember') || '',
      otp: ['', '', '', ''],
    }
  },

  validations: {
    phoneNumber: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(10),
      valid: value => /^(0[2689]{1}[0-9]{8})$/.test(value),
    },
    otp: {
      $each: {
        required,
      },
    },
  },

  mounted() {
    if (localStorage.getItem('timeUntilNextRequest')) {
      this.countDown = moment
        .unix(localStorage.getItem('timeUntilNextRequest'))
        .diff(moment(), 'seconds')

      this.countDownTimer()
    }
  },

  methods: {
    open() {
      this.otp = ['', '', '', '']
      this.dialog = true
    },

    close() {
      localStorage.removeItem('timeUntilNextRequest')
      this.countDownTimer()

      this.dialog = false
    },

    phoneNumberErrorMessage() {
      if (this.$v.phoneNumber.$error) {
        if (!this.$v.phoneNumber.required) {
          return 'กรุณาระบุหมายเลขโทรศัพท์'
        }
        if (
          !this.$v.phoneNumber.minLength ||
          !this.$v.phoneNumber.minLength
        ) {
          return 'กรุณาตรวจสอบว่าระบุหมายเลขโทรศัพท์ถูกต้อง'
        }
        if (!this.$v.phoneNumber.valid) {
          return 'หมายเลขโทรศัพท์ไม่ถูกต้อง'
        }
      }
      return null
    },
    /**
     * Handles the input event for the autocomplete OTP input fields.
     * @param {Event} event - The input event object.
     * @param {number} index - The index of the current input field.
     */
    handleInput(event, index) {
      this.$store.dispatch('user/resetVerifyOtpMember')

      const value = event.target.value
      if (value.length > 1) {
        this.otp.splice(
          index,
          value.length,
          ...value.slice(0, this.otp.length - index),
        )
        this.$nextTick(() => {
          this.$refs.otpInputs.children[index + value.length].focus()
        })
      }
    },

    /**
     * Handles the keydown event for OTP input fields.
     *
     * @param {Event} event - The keydown event object.
     * @param {number} index - The index of the OTP input field.
     */
    handleKeydown(event, index) {
      this.$store.dispatch('user/resetVerifyOtpMember')

      const key = event.key

      if (!['Tab', 'ArrowRight', 'ArrowLeft'].includes(key)) {
        event.preventDefault()
      }

      if (key === 'Backspace') {
        if (event.target.value) {
          this.otp.splice(index, 1, null)
          event.target.value = null
          return
        } else {
          if (index !== 0) {
            this.$refs.otpInputs.children[index - 1].focus()
            return
          }
        }
      }

      // Allow only 0-9
      if (/^([0-9])$/.test(key)) {
        this.otp.splice(index, 1, key)
        event.target.value = key

        const SMS_DEFAULT = 4

        if (index !== SMS_DEFAULT - 1) {
          this.$refs.otpInputs.children[index + 1].focus()
          return
        }

        this.$refs.otpInputs.children[index].blur()
      }
    },

    async handleOtpMemberRequest() {
      this.$v.phoneNumber.$touch()

      if (this.$v.phoneNumber.$invalid) {
        return
      }

      const payload = {
        value: this.phoneNumber,
        type: 'phone',
      }

      try {
        await this.$store.dispatch('user/otpMemberRequest', payload)

        if (this.isSuccess) {
          this.open()

          localStorage.setItem(
            'timeUntilNextRequest',
            moment()
              .add(31, 'seconds')
              .unix(),
          )

          this.countDown = moment
            .unix(localStorage.getItem('timeUntilNextRequest'))
            .diff(moment(), 'seconds')
          this.countDownTimer()
        }

        if (this.isError) {
          this.$emit('error')
        }
      } catch {
        this.$emit('error')
      }

      this.$v.$reset()
    },

    async handleVerifyOtpMember() {
      this.$v.otp.$touch()

      if (this.$v.otp.$invalid) {
        return
      }

      const payload = {
        uuid: this.uuid,
        otp: this.otp.join(''),
      }

      try {
        await this.$store.dispatch('user/verifyOtpMember', payload)

        if (this.isVerifySuccess) {
          this.close()

          this.countDown = 0
          clearInterval(this.countDownInterval)

          this.phoneNumber =
            this.member?.phone ||
            localStorage.getItem('isMember') ||
            ''

          this.$emit('verified')
        }

        if (this.isError) {
          // reset otp
        }
      } catch {
        this.$emit('error')
      }

      this.$v.$reset()
    },

    countDownTimer() {
      // Clear existing interval if any
      if (this.countDownInterval) {
        clearInterval(this.countDownInterval)
      }

      this.countDownInterval = setInterval(() => {
        this.countDown = moment
          .unix(localStorage.getItem('timeUntilNextRequest'))
          .diff(moment(), 'seconds')

        if (this.countDown <= 0) {
          localStorage.removeItem('timeUntilNextRequest')
          clearInterval(this.countDownInterval)
        }
      }, 1000)
    },

    openMemberLogout() {
      this.dialogLogout = true
    },

    closeMemberLogout() {
      this.dialogLogout = false
    },

    handleMemberLogout() {
      this.closeMemberLogout()

      this.countDown = 0
      clearInterval(this.countDownInterval)

      this.$store.dispatch('user/resetMember')
      this.$emit('reset')
    },

    handleScanQrCode() {
      this.$refs.QrCodeReader.openScanner()
    },

    async handleResultQrCodeReader(event) {
      if (event) {
        const payload = {
          clubmember_key: event,
        }

        try {
          await this.$store.dispatch('user/getMemberByKey', payload)

          if (this.isSuccess) {
            this.phoneNumber =
              this.member?.phone ||
              localStorage.getItem('isMember') ||
              ''

            this.$emit('verified')
          }
        } catch {
          this.$emit('error')
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.header-modal-container
  padding: 24px 16px 8px

.header-modal
  display: flex
  justify-content: center
  width: 100%

.body-modal
  padding: 16px

.header-modal-title
  font-size: 18px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.empty-data
	text-align: center
	color: $color-bnn-gray-medium-2
	padding: 48px 0

.link
  width: fit-content
  display: inline
  color: $color-bnn
  text-decoration: underline
  cursor: pointer
  &.disabled
    color: $color-bnn-gray-light
    text-decoration: none
    cursor: not-allowed

.input-container {
	.input-otp-container {
		display: flex;
		flex-wrap: nowrap;
		gap: 12px;
		justify-content: center;
		margin-bottom: 16px;

		&.is-invalid {
			.input-otp {
				box-shadow: 0 0 0 1px $color-red;
			}
		}

		.input-otp {
			width: 100%;
			height: 68px;
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			border: none;
			box-shadow: 0 0 0 1px $color-bnn-gray-light;
			border-radius: 4px;

			&:hover {
				box-shadow: 0 0 0 1px $color-bnn-border;
			}

			&:focus,
			&:focus-visible {
				box-shadow: 0 0 0 2px $color-bnn-border;
				outline: none;
			}

			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type=number] {
				-moz-appearance: textfield;
			}
		}
	}
}

.divider-with-text {
	display: flex;
	align-items: center;
	text-align: center;
	color: black;
	font-size: 14px;

	&::before,
	&::after {
	  content: '';
	  flex: 1;
	  border-bottom: 1px solid #dedede;
	}
	&:not(:empty)::before {
	  margin-right: 16px;
	}
	&:not(:empty)::after {
	  margin-left: 16px;
	}
}

.btn-scan-qr-code {
  font-weight: bold;
  color: $color-dark-gray-medium;

  .v-icon {
	color: $color-bnn-border
  }
}

.desc-seven-club-member {
	text-align: center;
	font-size: 14px;
	color: $color-dark-gray-medium;
}

.error-message {
	color: $color-red;
	font-size: 12px;
	text-align: center;
	margin-bottom: 8px;
}

.member-container:not(.is-pre-register) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
}

.member-container.is-pre-register {
    width: 100%
    display: flex
    flex-direction: column
    gap: 14px
    justify-content: center
    > div:first-child {
        width: 100%
        flex-shrink: 0

        @media screen and (min-width: 768px) {
            max-width: 320px
        }
    }

    @media screen and (min-width: 768px) {
        flex-direction: row
        flex-wrap: wrap

        > div:first-child {
            width: 100%
            max-width: calc(100% - 180px)
            flex-shrink: 0

            @media screen and (min-width: 768px) {
                max-width: 320px
            }
        }
    }
}

.seven-club-member-studio7 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.title-seven-club-member{
    display inline-flex
    justify-content center
    align-items center
    padding-bottom 16px
}
</style>
