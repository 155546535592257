<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      dark
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            Scan QR Code
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn depressed rounded text @click="closeScanner">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>

        <div
          id="qr-code-reader"
          style="width:100%; height: 100%;"
        ></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode'

export default {
  name: 'QrCodeReader',
  mounted() {},
  data() {
    return {
      dialog: false,
      html5QrCode: null,
    }
  },
  computed: {
    isHtml5QrCode() {
      return !!this.html5QrCode || false
    },
  },
  methods: {
    openScanner() {
      this.dialog = true

      setTimeout(() => {
        this.startScanner()
      }, 1000)
    },
    closeScanner() {
      this.dialog = false

      // Ensure to cleanup and stop the scanner when the component is destroyed
      if (this.isHtml5QrCode) {
        this.html5QrCode.stop().catch(() => {
          //   Unable to stop scanning
          alert('เกิดข้อผิดพลาดในการหยุดการสแกน')
        })
      }
    },

    async startScanner() {
      this.html5QrCode = new Html5Qrcode('qr-code-reader')
      const config = { fps: 10, qrbox: { width: 300, height: 300 } }

      if (this.isHtml5QrCode) {
        try {
          const cameras = await Html5Qrcode.getCameras()
          if (cameras && cameras.length > 0) {
            // Start scanning
            this.html5QrCode.start(
              { facingMode: { exact: 'environment' } },
              config,
              // eslint-disable-next-line no-unused-vars
              (decodedText, decodedResult) => {
                this.$emit('result', decodedText)

                this.html5QrCode
                  .stop()
                  .then(() => {
                    this.dialog = false
                  })
                  .catch(() => {
                    // Failed to stop the scanner
                    this.dialog = false
                    alert('เกิดข้อผิดพลาดในการหยุดการสแกน')
                  })
              },
            )
          } else {
            this.dialog = false
            // No cameras found. Please ensure your device has a camera and camera access is allowed
            alert(
              'ไม่พบกล้อง โปรดตรวจสอบว่าอุปกรณ์ของคุณมีกล้องและอนุญาตให้เข้าถึงกล้อง',
            )
          }
        } catch {
          this.dialog = false
          //   Failed to start the scanner. Please ensure camera access is allowed.
          alert(
            'ไม่สามารถเริ่มสแกนได้ โปรดตรวจสอบสิทธิ์การเข้าถึงกล้อง',
          )
        }
      } else {
        // Failed to start the scanner. Please ensure camera access is allowed.
        alert(
          'ไม่สามารถเริ่มสแกนได้ โปรดตรวจสอบสิทธิ์การเข้าถึงกล้อง',
        )
      }
    },
  },
  beforeDestroy() {
    // Ensure to cleanup and stop the scanner when the component is destroyed
    if (this.isHtml5QrCode) {
      this.html5QrCode.stop().catch(() => {
        // Unable to stop scanning
        alert('เกิดข้อผิดพลาดในการหยุดการสแกน')
      })
    }
  },
}
</script>

<style lang="stylus" scoped>
#qr-code-reader {
  width: 100%;
  height: 100%;

  max-width: 100dvw;
  max-height: 100dvh;
}
</style>
